import PropTypes from "prop-types"
import clsx from "clsx"
import useHighlightComponents from "js/hooks/use_highlight_components"
import { useGAEventFactory } from "js/hooks/analytics"
import { Container, EmbedLink } from "./styles"
import { useLayoutEditor } from "js/containers/layout_context_provider/use_layout_editor"

function VisualizationHighlight({
  Visualization,
  visualization,
  props: vizProps,
}) {
  const { cycle, date = `cycle-${cycle}` } = vizProps

  const [highlight] = useHighlightComponents()
  const { setGrid } = useLayoutEditor()

  const grid = [
    {
      id: "0",
      date,
      visualization,
      props: vizProps,
      col: [1, 3],
      row: [1, 2],
    },
  ]

  const gaEvents = useGAEventFactory()
  const onEmbedClick = () => {
    gaEvents.navigation({
      action: "Embed Component from Dashboard",
      label: `Embed ${date} ${visualization}`,
    })
    setGrid({ slots: grid }, "")
  }

  // For WalkMe documentation mounting
  const containerClass = clsx(
    "microsite-walkme-mount",
    "walkme--embeddable-component",
    `walkme--embeddable-component-${visualization}`,
    {
      "walkme--embeddable-component-highlighted": highlight,
      "walkme--embeddable-component-not-highlighted": !highlight,
    }
  )

  return (
    <Container className={containerClass} $highlight={highlight}>
      {highlight && (
        <EmbedLink to={`/layout-management/layouts/new`} onClick={onEmbedClick}>
          Embed
        </EmbedLink>
      )}
      <Visualization
        visualization={visualization}
        props={vizProps}
        key={highlight ? 1 : 0} // Add key to force viz to remount when highlight is toggled
      />
    </Container>
  )
}

VisualizationHighlight.propTypes = {
  Visualization: PropTypes.elementType,
  visualization: PropTypes.string,
  props: PropTypes.object,
}

export default VisualizationHighlight
