import { getDisplayName } from "./utils"
import VisualizationHighlight from "js/components/visualization_highlight"

export default function withVisualizationHighlight() {
  return (Visualization) => {
    function C(props) {
      return <VisualizationHighlight Visualization={Visualization} {...props} />
    }

    C.displayName = `WithVisualizationHighlight(${getDisplayName(
      Visualization
    )})`

    return C
  }
}
