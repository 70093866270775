import { useCallback } from "react"
import useAuth from "./use_auth"

function buildUrl(auth, url = "/") {
  const baseUrl = `${MICROSITE_SERVICE_BASE_URL}/api/v1`
  const orgPrefix = `organizations/${auth.auth.user.OrganizationId}`
  const path = url.startsWith("/") ? url.slice(1) : url
  return [baseUrl, orgPrefix, path].filter(Boolean).join("/")
}

function buildFetchConfig(auth, extraConfig = {}) {
  const token = auth.auth.token
  const { headers = {}, ...fetchConfig } = extraConfig
  return {
    ...fetchConfig,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }
}

export function useLayoutData() {
  const auth = useAuth()

  const getLayoutData = useCallback(
    function ({ callback, path }) {
      const url = buildUrl(auth, path)
      const config = buildFetchConfig(auth)

      return fetch(url, config)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((data) => callback(data))
    },
    [auth]
  )

  const postLayoutData = useCallback(
    function ({ payload, path, callback, errorHandler }) {
      const url = buildUrl(auth, path)
      const config = buildFetchConfig(auth, {
        method: "POST",
        body: JSON.stringify(payload),
      })

      return fetch(url, config)
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw Error(response.status)
          }
        })
        .then((data) => {
          if (callback) {
            // If a callback is provided we run that.
            callback(data)
          } else {
            // If not we return a resolved promise so the
            // caller can continue chaining methods.
            return Promise.resolve(data)
          }
        })
        .catch((error) => {
          if (errorHandler) {
            errorHandler()
          } else {
            console.error(error)
          }
        })
    },
    [auth]
  )

  return { getLayoutData, postLayoutData }
}
