import { createContext, useReducer } from "react"
import PropTypes from "prop-types"
import { layoutReducer } from "./reducer"

export const LayoutContext = createContext(null)

export function LayoutContextProvider({ children }) {
  const [layoutState, dispatch] = useReducer(layoutReducer, {
    layoutMetadata: {},
    hasUnsavedChanges: false,
    menuParams: {},
    layouts: [],
    folders: [],
    activeFolder: "",
    error: "",
    name: "",
    nameIsDuplicate: true,
    grid: {},
    page: 0,
    listOrder: "lastUpdated",
    layoutPage: [],
    perPage: 10,
  })

  return (
    <LayoutContext.Provider value={{ layoutState, dispatch }}>
      {children}
    </LayoutContext.Provider>
  )
}

LayoutContextProvider.propTypes = {
  children: PropTypes.node,
}
