import PropTypes from "prop-types"
import { useLocation, useMatch } from "react-router-dom"
import ErrorBoundary from "./ErrorBoundary"

function RouteErrorBoundary({ resetKeys = [], ...props }) {
  // We have to call useRouteMatch here to force this component to rerender
  // when the routing state changes, but we have to use the history object to
  // get the current pathname because that includes the full path, not just the
  // path that has been matched up to whichever router this is rendered under
  useMatch("*")
  const location = useLocation()

  return (
    <ErrorBoundary {...props} resetKeys={[...resetKeys, location.pathname]} />
  )
}

RouteErrorBoundary.propTypes = {
  resetKeys: PropTypes.array,
}

export default RouteErrorBoundary
