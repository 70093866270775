import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"

export const InformationalComponentHighlightContext = React.createContext([
  false,
  () => {},
])

function InformationalComponentHighlightProvider({ children }) {
  const [highlight, setHighlight] = useState(false)

  const value = useMemo(() => {
    return {
      highlight,
      setHighlight,
    }
  }, [highlight, setHighlight])

  return (
    <InformationalComponentHighlightContext.Provider value={value}>
      {children}
    </InformationalComponentHighlightContext.Provider>
  )
}

InformationalComponentHighlightProvider.propTypes = {
  /** Children to render inside provider */
  children: PropTypes.node,
}

export default InformationalComponentHighlightProvider
