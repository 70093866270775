import { useContext, useCallback, useEffect } from "react"
import { useLayoutData } from "js/hooks/use_layout_data"
import { LayoutContext } from "./LayoutContextProvider"
import { useGAEventFactory } from "js/hooks/analytics"
import { useNavigate, useMatch } from "react-router-dom/"

let checkNameTimeout = 0

export function useLayoutEditor() {
  const gaEvents = useGAEventFactory()
  const { layoutState, dispatch } = useContext(LayoutContext)
  const {
    menuParams,
    grid,
    error,
    name,
    nameIsDuplicate,
    hasUnsavedChanges,
    layoutMetadata,
    draftVersion,
    promptAction,
  } = layoutState

  const match = useMatch("/layout-management/layouts/:id/*")
  const id = match?.params.id
  const layoutId = parseInt(id) || null
  const navigate = useNavigate()
  const { getLayoutData, postLayoutData } = useLayoutData()

  const nameIsEmpty = !name

  useEffect(() => {
    if (id !== "new" && layoutId === null && !hasUnsavedChanges) {
      dispatch({ type: "RESET_GRID" })
    }
  }, [layoutId, dispatch, id, hasUnsavedChanges])

  function setGrid(grid, name, hasUnsavedChanges) {
    dispatch({ type: "SET_GRID", grid, hasUnsavedChanges, name })
  }

  function resetGrid() {
    dispatch({ type: "RESET_GRID" })
  }

  function setMenuParams(menuParams) {
    dispatch({ type: "SET_MENU_PARAMS", menuParams })
  }

  function setName(name) {
    clearTimeout(checkNameTimeout)
    dispatch({ type: "SET_NAME", name })
    checkNameTimeout = setTimeout(() => checkName(name), 500)
  }

  function setError(error) {
    dispatch({ type: "SET_ERROR", error })
  }

  function setPromptAction(action) {
    if (action === "") {
      setError("")
    }
    dispatch({ type: "SET_PROMPT_ACTION", action })
  }

  const getLayout = useCallback(
    function (id, newLayout = false) {
      getLayoutData({
        path: `layouts/${id}`,
        callback(data) {
          dispatch({ type: "SET_EDITING_SLOT", editingSlot: undefined })

          const {
            draftLayoutVersionId,
            publishedLayoutVersionId,
            versions = [],
          } = data

          const publishedVersion = versions.find(
            (version) => version.id === publishedLayoutVersionId
          )

          const draftVersion = versions.find(
            (version) => version.id === draftLayoutVersionId
          )

          const draftVersionGridStr = draftVersion.grid
          dispatch({
            type: "SET_GRID",
            grid: JSON.parse(draftVersionGridStr),
            name: data.name,
            hasUnsavedChanges: false,
          })

          dispatch({
            type: "UPDATE_REQUEST_DATA",
            draftLayoutVersionId,
            publishedLayoutVersionId,
            draftVersion,
            publishedAt: publishedVersion?.publishedAt,
            updatedAt: draftVersion?.updatedAt,
          })

          if (newLayout) {
            navigate(`/layout-management/layouts/${id}/edit`)
          }

          dispatch({ type: "SET_ID", id })
        },
      })
    },
    [dispatch, getLayoutData, navigate]
  )

  function checkName(name) {
    dispatch({ type: "SET_NAME_IS_DUPLICATE", nameIsDuplicate: true })
    getLayoutData({
      path: `layouts/checkname?layoutId=${id}&name=${name}`,
      callback({ valid }) {
        dispatch({ type: "SET_NAME_IS_DUPLICATE", nameIsDuplicate: !valid })
      },
    })
  }

  function createLayout(followUp) {
    dispatch({ type: "SET_CHANGES", changes: false })
    return postLayoutData({
      path: "layouts",
      payload: { name: name.trim(), grid },
    })
      .then(({ id }) => {
        gaEvents.layouts({
          action: "Save Layout",
          label: "Save New Layout",
        })
        followUp(id, true)
      })
      .catch(() => {
        dispatch({ type: "SET_CHANGES", changes: true })
      })
  }

  function saveLayout(followUp) {
    return postLayoutData({
      path: `layouts/${layoutId}`,
      payload: { name: name.trim() },
    })
      .then(() => {
        return postLayoutData({
          path: `layouts/${layoutId}/versions/${draftVersion.id}`,
          payload: { grid: JSON.stringify(grid) },
        })
      })
      .then(() => {
        gaEvents.layouts({
          action: "Save Layout",
          label: "Save Existing Layout",
        })

        followUp(layoutId)
      })
      .catch(() => {
        dispatch({ type: "SET_CHANGES", changes: true })
      })
  }

  function deleteLayout() {
    const changes = hasUnsavedChanges
    dispatch({ type: "SET_CHANGES", changes: false })
    postLayoutData({
      path: `layouts/${layoutId}/delete`,
      callback() {
        gaEvents.layouts({ action: "Delete Layout" })
        navigate("/layout-management/layouts")
      },
      handleError() {
        dispatch({ type: "SET_CHANGES", changes })
      },
    })
  }

  function saveChanges(followUp) {
    if (nameIsEmpty || nameIsDuplicate) {
      setError(nameIsEmpty ? "empty" : "duplicate")
      dispatch({ type: "SET_CHANGES", changes: true })
      return
    }

    setPromptAction("")
    if (!layoutId) {
      createLayout(followUp)
    } else {
      saveLayout(followUp)
    }
  }

  function publishLayout(id) {
    const changes = hasUnsavedChanges
    dispatch({ type: "SET_CHANGES", changes: false })
    postLayoutData({
      path: `layouts/${id}/publish`,
      callback() {
        gaEvents.layouts({ action: "Publish Layout" })
        navigate("/layout-management/layouts")
      },
      handleError() {
        dispatch({ type: "SET_CHANGES", changes })
      },
    })
  }

  function save() {
    setPromptAction("save")
    saveChanges(getLayout)
  }

  function publish() {
    setPromptAction("publish")
    saveChanges(publishLayout)
  }

  function setCustomCSS(customCSS) {
    setGrid({ ...grid, customCSS }, name, true)
  }

  return {
    layoutId,
    menuParams,
    hasUnsavedChanges,
    layoutMetadata,
    name,
    error,
    grid,
    promptAction,
    readyToSave: !(nameIsDuplicate || nameIsEmpty),
    resetGrid,
    getLayout,
    setError,
    setName,
    setCustomCSS,
    save,
    publish,
    deleteLayout,
    setGrid,
    setMenuParams,
  }
}
