import { getDisplayName } from "./utils"
import { RouteErrorBoundary } from "js/containers/error_boundary"

export default function withRouteErrorBoundary(errorBoundaryProps = {}) {
  return (Component) => {
    function C(props) {
      return (
        <RouteErrorBoundary {...errorBoundaryProps}>
          <Component {...props} />
        </RouteErrorBoundary>
      )
    }

    C.displayName = `WithRouteErrorBoundary(${getDisplayName(Component)})`

    return C
  }
}
