import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

const highlight = (props) => {
  if (!props.$highlight) return ""
  return css`
    outline: 1px solid #777;
    box-shadow:
      0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  `
}

export const Container = styled.div`
  position: relative;
  ${highlight}
`

export const EmbedLink = styled(Link)`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 0.2em 1em;
  background-color: #777;
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: white;
  }
`
