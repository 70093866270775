import { lazy, useState, useEffect } from "react"
import withSuspense from "js/hoc/with_suspense"
import withVisualizationHighlight from "js/hoc/with_visualization_highlight"
import withRouteErrorBoundary from "js/hoc/with_route_error_boundary"
import withErrorBoundary from "js/hoc/with_error_boundary"
import { compose } from "js/hoc/utils"
import {
  loadElectionContainer,
  loadElectionModule,
} from "./federated_election_utils"

export default function useFederatedElections(dates, opts = { slim: false }) {
  const { slim } = opts
  const [elections, setElections] = useState({})
  const serializedDates = JSON.stringify(dates)
  useEffect(() => {
    const dates = JSON.parse(serializedDates)
    const loadRemoteEntry = async (date) => {
      await __webpack_init_sharing__("default")

      const year = Number(date.match(/\d{4}/)[0])
      const dateToLoad = year >= 2025 ? `cycle-${year}` : date

      let container
      try {
        container = await loadElectionContainer(dateToLoad)
      } catch (error) {
        return setElections((els) => ({
          ...els,
          [date]: {
            failed: true,
            error,
          },
        }))
      }

      await container.init(__webpack_share_scopes__.default)

      const Block = lazy(() => loadElectionModule(container, "./Block"))
      const Visualization = lazy(() =>
        loadElectionModule(container, "./Visualization")
      )

      let nonSlimContent = {}
      if (!slim) {
        const customization = (
          await loadElectionModule(container, "./customization")
        ).default
        const recommended_layouts = (
          await loadElectionModule(container, "./recommended_layouts")
        ).default
        nonSlimContent = { customization, recommended_layouts }
      }

      const enhanceViz = slim
        ? compose(
            withErrorBoundary({
              message: `We're sorry, but there was a problem with this visualization.`,
            }),
            withSuspense
          )
        : compose(
            withVisualizationHighlight(),
            withRouteErrorBoundary({
              message: `We're sorry, but there was a problem with this visualization.`,
            }),
            withSuspense
          )

      setElections((els) => ({
        ...els,
        [date]: {
          Block: withSuspense(Block),
          Visualization: enhanceViz(Visualization),
          ...nonSlimContent,
          ready: true,
        },
      }))
    }
    dates.forEach((date) => loadRemoteEntry(date))
  }, [serializedDates, slim])
  return elections
}
